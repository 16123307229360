import { goToByScroll } from './go-to-by-scroll';
import { utils } from "./utils";
//import { gsap } from '../libs/gsap.min';
//import { ScrollTrigger } from '../libs/ScrollTrigger.min';

const body = document.body;

let hoverTimer;
const hoverDelay = 100;

/**
 * Toggle accordions on click
 */
document.querySelectorAll('.child-cats .toggle-accordion').forEach(el => {

	el.addEventListener('click', function (e) {
		const toggle = el;

		const popupBody = el.closest('.popup__body');
		const parentCat = popupBody.querySelector('.parent-cat');
		const childCatImgsContainer = parentCat.querySelector('.parent-cat__child-cat-imgs-container');

		const content = toggle.nextElementSibling;
		const otherToggles = [...toggle.parentNode.querySelectorAll('.toggle-accordion')].filter(el => !el.isEqualNode(toggle));
		const otherContents = [...toggle.parentNode.querySelectorAll('nav')].filter(el => !el.isEqualNode(content));

		otherToggles.forEach((el) => {
			el.classList.remove('active');
		});

		otherContents.forEach((el) => {
			utils.slideUp(el, 600);
		});

		parentCat.classList.remove('invert');
		childCatImgsContainer.innerHTML = '';
	});

});


/**
 * Change category images on hover
 */
document.querySelectorAll('.child-cats .title[data-img-url]').forEach(el => {

	let hoverTimer;
	let originalMainImgUrl; // Variabile per memorizzare l'URL dell'immagine principale originale
	let hoverDelay = 300; // Aggiungi la variabile hoverDelay se non è già definita

	if (matchMedia('(any-hover: hover)').matches) {
		el.addEventListener('mouseenter', handleHoverEvent);
		el.addEventListener('click', handleClickEvent);

		el.addEventListener('mouseleave', function (e) {
			clearTimeout(hoverTimer);
		});
	}

	function handleHoverEvent(e) {
		clearTimeout(hoverTimer);

		// Dopo aver aspettato il hoverDelay...
		hoverTimer = setTimeout(function () {
			updateImage(el);
		}, hoverDelay);
	}

	function handleClickEvent(e) {
		clearTimeout(hoverTimer);
		updateImage(el);
	}

	function updateImage(el) {
		const popupBody = el.closest('.popup__body');
		const parentCat = popupBody.querySelector('.parent-cat');
		const childCatImgsContainer = parentCat.querySelector('.parent-cat__child-cat-imgs-container');

		// Memorizza l'URL dell'immagine principale originale se presente
		const mainImg = parentCat.querySelector('.parent-cat__bg');
		if (mainImg) {
			originalMainImgUrl = mainImg.src;
			mainImg.remove();
		}

		parentCat.classList.add('invert');

		const oldImg = childCatImgsContainer.querySelector('img:last-child');

		if (oldImg !== null) {
			oldImg.classList.remove('fadein');
			oldImg.classList.add('fadeout');

			setTimeout(function () {
				oldImg.remove();
			}, 300);
		}

		const newImg = document.createElement('img');
		newImg.src = el.dataset.imgUrl;
		newImg.classList.add('fadein');
		childCatImgsContainer.appendChild(newImg);
	}

	// Funzione per gestire la chiusura del popup e ripristinare l'immagine principale originale
	function closePopup() {
		const popupBody = el.closest('.popup__body');
		const parentCat = popupBody.querySelector('.parent-cat');
		const childCatImgsContainer = parentCat.querySelector('.parent-cat__child-cat-imgs-container');

		// Rimuovi l'immagine aggiunta
		const addedImg = childCatImgsContainer.querySelector('img');
		if (addedImg) {
			addedImg.remove();
		}

		// Ripristina l'immagine principale originale se presente
		const mainImg = parentCat.querySelector('.parent-cat__bg');
		if (!mainImg && originalMainImgUrl) {
			const newMainImg = document.createElement('img');
			newMainImg.src = originalMainImgUrl;
			newMainImg.classList.add('parent-cat__bg');
			parentCat.appendChild(newMainImg);
			parentCat.classList.remove('invert');
		}
	}

	// Aggiungi un listener per l'evento di chiusura del popup
	document.addEventListener('closePopup', closePopup);
});






document.querySelectorAll('.go-to-product').forEach(el => {

	el.addEventListener('click', function (e) {
		const product = el.closest('.product');
		const link = product.querySelector('.woocommerce-loop-product__link');

		location.href = link;
	});
});


/**
 * Open / close woocommerce filters on mobile
 */
const woocommerceFiltersToggle = document.querySelector('.woocommerce-filters-toggle');

if (woocommerceFiltersToggle !== null) {
	woocommerceFiltersToggle.addEventListener('click', function () {
		body.classList.toggle('woocommerce-filters-open');
	});
}

/**
 * Close woocommerce filters on mobile
 */
const contentSidebarClose = document.querySelector('.content-sidebar__close');

if (contentSidebarClose !== null) {
	contentSidebarClose.addEventListener('click', function () {
		body.classList.remove('woocommerce-filters-open');
	});
}


/**
 * Close accordions and reset category images when the popup is closed
 */
body.addEventListener('closePopup', function (e) {
	const popup = e.detail.popup;
	const popupBody = popup.querySelector('.popup__body');
	const parentCat = popupBody.querySelector('.parent-cat');
	const childCatImgsContainer = parentCat.querySelector('.parent-cat__child-cat-imgs-container');

	const toggles = [...popupBody.querySelectorAll('.toggle-accordion')];
	const contents = [...popupBody.querySelectorAll('nav')];

	toggles.forEach((el) => {
		el.classList.remove('active');
	});

	contents.forEach((el) => {
		utils.slideUp(el, 600);
	});

	parentCat.classList.remove('invert');
	childCatImgsContainer.innerHTML = '';
});

const openFeaturedProductCategories = document.querySelector('.open-featured-product-categories');

if (openFeaturedProductCategories !== null) {
	openFeaturedProductCategories.addEventListener('click', function (e) {
		document.body.classList.toggle('featured-product-categories-open');
	});
}

const featuredProductCategoriesClose = document.querySelector('.featured-product-categories__close');

if (featuredProductCategoriesClose !== null) {
	featuredProductCategoriesClose.addEventListener('click', function (e) {
		document.body.classList.remove('featured-product-categories-open');
	});
}


/**
 * Add wrapper to Woocommerce selects
 */

document.querySelectorAll('.woocommerce select').forEach(el => {
	const wrapper = document.createElement('div');
	wrapper.classList.add('select-wrapper');

	utils.wrap(el, wrapper);
});


/**
 * Add data-text attribute to #menu-navigazione-mega-menu links
 */
document.querySelectorAll('#menu-navigazione-mega-menu a').forEach(el => {
	el.dataset.text = el.textContent;
});



/**
 * ON LOAD
 */

window.addEventListener('load', function (event) {


});



/**
 * ON RESIZE
 */

window.addEventListener('resize', function (event) {
	if (event.target === window) {
		// trigger resizeEnd event only when the window resizing ends
		if (this.resizeTO) {
			clearTimeout(this.resizeTO);
		}
		this.resizeTO = setTimeout(function () {
			const event = new CustomEvent('resizeEnd');
			window.dispatchEvent(event);
		}, 200);
	}
});



/**
 * ON RESIZE END
 */

// do something when the window resizing ends
window.addEventListener('resizeEnd', function () {



});



/**
 * ON SCROLL
 */
/*window.addEventListener('scroll', function() {
});*/