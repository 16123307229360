//================================= TOGGLE MENU

const body = document.querySelector('body');

document.addEventListener('click', function (e) {
	const menuToggle = document.getElementById('menu-toggle');

	// check if menuToggle exists
	if (menuToggle === null)
	{
		return;
	}

	// return if the clicked element is not #menu-toggle nor is contained in #menu-toggle
	if (e.target !== menuToggle && !menuToggle.contains(e.target))
	{
		return;
	}

	menuToggle.classList.toggle('tcon-transform');

	// open/close mobile menu
	body.classList.toggle('menu-open');

	let toggleLabel = menuToggle.querySelector('.tcon-label');

	if (toggleLabel !== null)
	{
		toggleLabel.textContent = (body.classList.contains('menu-open')) ? toggleLabel.dataset.labelClose : toggleLabel.dataset.labelMenu;
	}
});

let menuClose = document.querySelector('#menu-close');

if (menuClose !== null)
{
	menuClose.addEventListener('click', function () {
		// close mobile menu
		body.classList.remove('menu-open');
	});
}
