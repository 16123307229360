import Venobox from '../libs/venobox.min'

new Venobox({
	selector: 'a.venobox, .gallery a, .module-slideshow a, .module-gallery a, .woocommerce-product-gallery__image a',
	numeration: true,
	overlayColor: 'rgba(0,0,0,0.95)',
	//maxWidth: '95%'
});

const venoboxVideoLinks = document.querySelectorAll('a.venobox-video');

venoboxVideoLinks.forEach((el) => {
	el.setAttribute("data-vbtype", "video");
});

new Venobox({
	selector: 'a.venobox-video',
	autoplay: true
});
