/**
 * ON DOCUMENT READY
 */

let ready = (callback) => {
	if (document.readyState !== "loading") {
		callback();
	}
	else {
		document.addEventListener("DOMContentLoaded", callback);
	}
}

ready(() => {
	import('./components/utils');

	import('./components/bind-link-to-item');

	import('./components/check-scroll');

	//import('./components/go-to-by-scroll');

	import('./components/toggle-menu');

	// import('./components/form');

	import('./components/mega-menu');


	import('./components/forms');

	import('./components/custom-checkbox-radio');

	import('./components/slideshows');

	import('./components/popups');

	import('./components/modal-box');

	import('./components/smooth-scroll');

	//import('./components/columns-extend');

	//import('./components/resize-video-iframe');

	import('./components/toggle-accordion');

	import('./components/search-form');

	import('./components/marquee');

	//import('./components/animations');

	//import('./components/equal-heights');

	import('./components/theme-scripts');
});

// script.js
document.addEventListener("DOMContentLoaded", function () {
	var popup = document.getElementById("popup-closed");
	var closeBtn = document.querySelector(".popup__close");

	// Funzione per ottenere il valore di un cookie
	function getCookie(name) {
		var value = "; " + document.cookie;
		var parts = value.split("; " + name + "=");
		if (parts.length == 2) return parts.pop().split(";").shift();
	}

	// Funzione per impostare un cookie
	function setCookie(name, value, days) {
		var expires = "";
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			expires = "; expires=" + date.toUTCString();
		}
		document.cookie = name + "=" + (value || "") + expires + "; path=/";
	}

	// Controlla se il cookie esiste
	if (!getCookie("popupDisplayed")) {
		console.log('io')
		// Mostra il popup se il cookie non esiste
		popup.classList.remove("popup-closed");
		popup.classList.add("popup--active");
		document.body.classList.add('popup-open');

		// Imposta il cookie per ricordare che il popup è stato mostrato
		setCookie("popupDisplayed", "true", 1); // Imposta il cookie per 1 giorno
	}

	// Aggiungi un event listener per il pulsante di chiusura
	closeBtn.addEventListener("click", function () {
		popup.classList.remove("popup--active");
		popup.classList.add("popup-closed");
		document.body.classList.remove('popup-open');
	});
});