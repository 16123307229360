const body = document.body;
const searchPopupContainer = document.querySelector('.search-popup');

/**
 * Open search popup
 * @param e
 * @returns {boolean}
 */
const openPopupSearch = function (e) {
	e.preventDefault();
	e.stopPropagation();

	// close popup search if already open
	if (body.classList.contains('popup-search-open'))
	{
		body.removeEventListener('click', closePopupSearch);
		body.classList.remove('popup-search-open');
		return false;
	}

	// open popup search
	body.classList.add('popup-search-open');
	body.classList.remove('menu-open');
	document.getElementById('menu-toggle').classList.remove('tcon-transform');

	searchPopupContainer.querySelector('.search-field').focus();

	// Close search popup when the user clicks outside the popup
	body.addEventListener('click', closePopupSearch);

	return false;
}

document.querySelector('.open-popup-search').addEventListener('click', openPopupSearch);

function closePopupSearch (e) {
	// if the target of the click isn't the searchPopupContainer nor a descendant of the searchPopupContainer
	if (searchPopupContainer !== e.target && !searchPopupContainer.contains(e.target))
	{
		body.classList.remove('popup-search-open');
		body.removeEventListener('click', closePopupSearch);
	}
}

/**
 * Close search popup on click
 * @type {Element}
 */
const closePopupSearchBtn = document.querySelector('.close-popup-search');

if (closePopupSearchBtn)
{
	closePopupSearchBtn.addEventListener('click', function (e) {
		e.preventDefault();

		// close popup search
		body.removeEventListener('click', closePopupSearch);
		body.classList.remove('popup-search-open');

		return false;
	});
}