import {utils} from "./utils";

/**
 * Marquee
 */
document.querySelectorAll('.marquee-inner').forEach((el, i) => {

	let translateWidth;
	const marqueeWidth      = el.parentNode.scrollWidth;
	const marqueeInnerWidth = el.scrollWidth;
	const speedCoefficient  = 15; // higher values for slower speed

	if (marqueeInnerWidth < marqueeWidth)
	{
		translateWidth = marqueeInnerWidth
	}
	else
	{
		translateWidth = marqueeWidth
	}

	// adapt marquee speed based on content length
	el.style.animationDuration = translateWidth / window.visualViewport.width * speedCoefficient + 's';

	el.parentNode.style.setProperty('--translate-amount', '-' + (translateWidth + 60) * 100 / window.visualViewport.width + '%');

	utils.duplicateChildNodes(el);
});