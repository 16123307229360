import {utils} from "./utils";

export function initAccordions() {

	let toggleAccordion = function (e) {
		e.preventDefault();

		let toggle = e.target;
		let transitionDuration = 600;

		if (toggle !== this && this.contains(toggle))
		{
			toggle = toggle.closest('.toggle-accordion');
		}

		if (toggle.classList.contains('animating'))
		{
			return;
		}
		else
		{
			toggle.classList.add('animating');
		}

		let content = toggle.nextElementSibling;

		if (content !== null)
		{
			toggle.classList.toggle('active');

			utils.slideToggle(content, transitionDuration);

			setTimeout(() => {
				toggle.classList.remove('animating');
			}, transitionDuration);
		}
	};

	document.querySelectorAll('.toggle-accordion').forEach(el => {

		el.removeEventListener('click', toggleAccordion);
		el.addEventListener('click', toggleAccordion);

	});

}

initAccordions();