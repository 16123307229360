const megaMenu           = document.querySelector('.mega-menu');
const toggleMegaMenuItem = document.querySelector('.menu-item--toggle-mega-menu');
const megaMenuDelay      = 200;
let megaMenuTimer;



/**
 * Disable click on .menu-item--toggle-mega-menu anchor
 */
/*$('a', toggleMegaMenuItem).on('click', function (e) {
	e.preventDefault();
	return false;
});*/


/**
 * Open the menu when over toggleMegaMenuItem
 */
if (matchMedia('(any-hover: hover)').matches)
{
	toggleMegaMenuItem.addEventListener('mouseenter', function () {

		if (matchMedia('(min-width: 992px)').matches)
		{
			openMegaMenu('.mega-menu', this);
		}

	});

	toggleMegaMenuItem.addEventListener('mouseleave', function () {

		if (matchMedia('(min-width: 992px)').matches)
		{
			closeMegaMenu();
		}

	});
}


/**
 * Close the menu when leaving the menu, clear timeout when entering the menu
 */
if (matchMedia('(any-hover: hover)').matches)
{
	megaMenu.addEventListener('mouseenter', function () {

		clearTimeout(megaMenuTimer);

	});

	megaMenu.addEventListener('mouseleave', function () {

		if (matchMedia('(min-width: 992px)').matches)
		{
			closeMegaMenu();
		}

	});
}


/**
 * Open the menu
 */
function openMegaMenu(menuToOpen, menuItemActive) {
	clearTimeout(megaMenuTimer);

	closeMegaMenu();

	// after hovering for megaMenuDelay, open the menu
	megaMenuTimer = setTimeout(function () {

		document.body.classList.add('mega-menu-open');

		menuItemActive.classList.add('active');

		document.querySelector(menuToOpen).classList.add('mega-menu--active');

	}, megaMenuDelay);
}


/**
 * Close the menu
 */
function closeMegaMenu() {
	clearTimeout(megaMenuTimer);

	// close .mega-menu after megaMenuDelay
	megaMenuTimer = setTimeout(function () {

		document.body.classList.remove('mega-menu-open');

		megaMenu.classList.remove('mega-menu--active');

		toggleMegaMenuItem.classList.remove('active');

	}, megaMenuDelay);
}